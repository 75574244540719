import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviorment } from '@core/settings/enviorment';
import { UsuarioService } from './usuario.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  constructor(public http: HttpClient,
              public _usuarioService: UsuarioService) { }

  getEmpresas(): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Companies/GetAllCompanies', { headers: token});
  }

  deleteEmpresa(idCompany): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Companies/DeleteCompany/' + idCompany, { headers: token});
  }

  getCompany(idCompany): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Companies/GetCompany/' + idCompany, { headers: token});
  }

  postCompany(company): Observable<any> {
    const token = this.getToken();
    return this.http.post(enviorment + 'Companies', company,  { headers: token} );
  }

  editCompany(company, id): Observable<any> {
    const token = this.getToken();
    return this.http.put(enviorment + 'Companies/' + id, company,  { headers: token} );
  }

  getToken() {
    const var12 = JSON.parse(localStorage.getItem('token'));
    const tokenHeader1 = new HttpHeaders({'Authorization': 'Bearer ' + var12});
    return tokenHeader1;
  }
}
